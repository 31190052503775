<style scoped>
	.border-icon {
		border: 1px rgb(172, 172, 172) solid;
		border-radius: 4px;
	}
	.switch {
		position: relative;
		display: inline-block;
		width: 53px;
		height: 25px;
		margin-bottom: 0;
	}

	.switch input {
		display: none;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 20px;
		width: 20px;
		left: 4px;
		bottom: 3px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	input:checked + .slider {
		background-color: #51C1AD;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #51C1AD;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(26px);
		-ms-transform: translateX(26px);
		transform: translateX(26px);
	}

	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
</style>

<template>
	<Layout>
		<!-- <PageHeader :title="title" :items="items" /> -->
		<div class="row" style="font-size:13px;">
			<div class="col-12">
				<ul class="nav nav-tabs pt-3" style="border: 0;">
					<li class="active"><a href="#" class="btn btn-sm btn-head text-white">General Information</a></li>
				</ul>
				<form @submit.prevent="submitData">
					<div class="card border">
						<div class="card-body">
							<div class="row mt-3">
								<div class="col-12">
									<div class="form-row">
										<div class="col-md-3">
											<label for="">Number <span class="text-danger" aria-required="true"> *
												</span></label>
											<div class="input-group">
												<v-select v-show="!isManualNumber" v-model="dataQuotation.autonumber_id"
													:reduce="(option) => option.id" :options="listAutonumber" label="name"
													style="width: 81%; float: left;">
												</v-select>
												<input v-show="isManualNumber" v-model="dataQuotation.numberText" type="text"
													class="form-control form-control-sm" placeholder="Number"  />
												<b-input-group-append style="width: 19%; float: right;">
													<b-button class="btn btn-create btn-sm" style="background: #51C1AD;"
														@click="triggerManualNumber(isManualNumber)">
														<i v-show="!isManualNumber" class="fa fa-pencil-alt fa-sm"></i>
														<i v-show="isManualNumber" class="mdi mdi-close"></i>
													</b-button>
												</b-input-group-append>
											</div>
										</div>
										
										<div class="col-md-3">
											<label for="">Input Date</label>
											<!-- <input v-model="dataQuotation.date_input" type="date" class="form-control" required @input="getTgl"> -->

											<date-picker v-model="dataQuotation.date_input" :format="momentFormat" type="date" lang="en"
												:placeholder="'Select Date'" @input="getTgl">
											</date-picker>
										</div>
										
										<div class="col-md-3 ">
											<label for="">Customer <span class="text-danger" aria-required="true"> *
												</span></label>
											<div class="input-group">
												<v-select v-show="!isHidden" v-model="dataQuotation.customer_id"
													:reduce="(option) => option.id" :options="listCustomerQuo" label="name"
													style="width: 81%; float: left;">
												</v-select>
												<input v-show="isHidden" v-model="dataQuotation.customer_manual" type="text"
													class="form-control form-control-sm" placeholder="Customer" :required = 'isHidden ? true : false' />
												<b-input-group-append style="width: 19%; float: right;">
													<b-button class="btn btn-create btn-sm" style="background: #51C1AD;"
														@click="manualInput(isHidden)">
														<i v-show="!isHidden" class="fa fa-pencil-alt fa-sm"></i>
														<i v-show="isHidden" class="mdi mdi-close"></i>
													</b-button>
												</b-input-group-append>
											</div>
										</div>
										<div class="col-md-3">
											<div class="form-group">
												<label for="">Subject</label>
												<input v-model="dataQuotation.subject" type="text" class="form-control form-control-sm"
													placeholder="Subject" required />
											</div>
										</div>
										<div class="col-md-3">
											<div class="form-group">
												<label for="">Attn</label>
												<input v-model="dataQuotation.pic_name" type="text" class="form-control form-control-sm"
													placeholder="Attn" required />
											</div>
										</div>
										<div class="col-md-3">
											<div class="form-group">
												<label for="">Port Of Delivery</label>
												<!-- <select v-model="dataQuotation.pod" class="form-control" data-live-search="true" placeholder="Choose an Customer" required>
													<option v-for="(isi, key) in listPort" :key="key" :value="isi.id">
														{{isi.name_port}}
													</option>
												</select> -->
												<!-- <v-select v-model="dataQuotation.podname" :options="listPort" label="name_port"
													@input="changePod">
												</v-select> -->
												<div class="input-group">
													<v-select v-show="!isManualPod" v-model="dataQuotation.pod_id"
														:reduce="(option) => option.id" :options="listPort" label="name_port"
														style="width: 81%; float: left;">
													</v-select>
													<input v-show="isManualPod" v-model="dataQuotation.podname" type="text"
														class="form-control form-control-sm"   />
													<b-input-group-append style="width: 19%; float: right;">
														<b-button class="btn btn-create btn-sm" style="background: #51C1AD;"
															@click="triggerManualPod(isManualPod)">
															<i v-show="!isManualPod" class="fa fa-pencil-alt fa-sm"></i>
															<i v-show="isManualPod" class="mdi mdi-close"></i>
														</b-button>
													</b-input-group-append>
												</div>
												<!-- <multiselect id="pod" v-model="dataQuotation.podname" placeholder="Port of Delivery" :searchable="true" :allow-empty="true" :clearable="true" :loading="isLoading" :close-on-select="true" :options="listPort" label="name_port" required @input="changePod"></multiselect> -->
											</div>
										</div>
										<div class="col-md-3">
											<div class="form-group">
												<label for="">Port of Loading</label>
												<!-- <select v-model="dataQuotation.pol" class="form-control" data-live-search="true" placeholder="Choose an Customer" required>
													<option v-for="(isi, key) in listPort" :key="key" :value="isi.id">
														{{isi.name_port}}
													</option>
												</select> -->
												<!-- <multiselect id="pol" v-model="dataQuotation.polname" placeholder="Port of Loading" :searchable="true" :allow-empty="true" :clearable="true" :loading="isLoading" :close-on-select="true" :options="listPort" label="name_port" required @input="changePol">
												</multiselect> -->
												
												<div class="input-group">
													<v-select v-show="!isManualPol" v-model="dataQuotation.pol_id"
														:reduce="(option) => option.id" :options="listPort" label="name_port"
														style="width: 81%; float: left;">
													</v-select>
													<input v-show="isManualPol" v-model="dataQuotation.polname" type="text"
														class="form-control form-control-sm" />
													<b-input-group-append style="width: 19%; float: right;">
														<b-button class="btn btn-create btn-sm" style="background: #51C1AD;"
															@click="triggerManualPol(isManualPol)">
															<i v-show="!isManualPol" class="fa fa-pencil-alt fa-sm"></i>
															<i v-show="isManualPol" class="mdi mdi-close"></i>
														</b-button>
													</b-input-group-append>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="form-group">
												<label for="">Term</label>
												<!-- <select v-model="dataQuotation.term_id" class="form-control" data-live-search="true" placeholder="Choose an Customer" required>
													<option v-for="(isi, key) in listTerm" :key="key" :value="isi.id">
														{{isi.name}}
													</option>
												</select> -->
												<!-- <multiselect id="term_quotation" v-model="dataQuotation.term" placeholder="Term Quotation" :searchable="true" :allow-empty="true" :clearable="true" :loading="isLoading" :close-on-select="true" :options="listTerm" label="name" required @input="changeTerm">
												</multiselect> -->
												<v-select v-model="dataQuotation.term" :options="listTerm" label="name" @input="changeTerm">
												</v-select>
											</div>
										</div>
										<div class="col-md-3">
											<label for="">Validity Date</label>
											<date-picker v-model="dataQuotation.validity_date" :format="momentFormat2" type="date" lang="en"
												:placeholder="'Select Date'" @input="getTgl2">
											</date-picker>
										</div>
										<div class="col-md-3">
											<div class="form-group">
												<label for="">Comodity</label>
												<input v-model="dataQuotation.comodity" type="text" class="form-control form-control-sm"
													placeholder="Comodity" />
											</div>
										</div>
										<div class="col-md-3">
											<label for=""></label>
											<div class="form-check mt-2">
												<input id="includeTax" v-model="includeTax" type="checkbox" true-value="1" false-value="0"
													class="form-check-input" style="width: 15px; height: 15px;" @change="cekIncludeTax">
												<label for="includeTax">Include Tax</label>
											</div>
										</div>


									</div>
								</div>
							</div>
						</div>
					</div>

					<ul class="nav nav-tabs " style="border: 0;">
						<li class="active"><a href="#" class="btn btn-sm btn-head text-white">Input Type Quotation</a></li>
					</ul>
					<div class="card border">
						<div class="card-body">
							<div class="row mt-3">
								<div class="col-12">
									<div class="form-row">
										<!-- form type quotation -->
										<div v-for="(item, k) in types" :key="k" class="col-md-12 mt-2">
											<div class="card border">
												<div class="card-header">
													<div class="row">
														<div class="col-md-5">
															<div class="row">
																<div class="col-md-3">
																	<label for="">Type Quotation <span class="text-danger">*</span></label>
																</div>
																<div class="col-md-6">
																	<input v-model="item.type" type="text" class="form-control form-control-sm"
																		placeholder="input type" required>
																</div>
																<div class="col-md-3">
																	<div class="form-check">
																		<input id="RenameItem" v-model="item.status_rename" type="checkbox" value="1"
																			:checked="dataQuotation.include_tax === 1" class="form-check-input"
																			style="width: 15px; height: 15px;" @change="cekRenameItem">
																		<label for="RenameItem">Rename Item</label>
																	</div>
																</div>
															</div>
														</div>
														<div class="col-md-7 text-right">
															<a v-show="k || (!k && types.length > 1)" href="javascript:void(0)"
																class="btn btn-sm btn-danger" @click="remove(k)"> <i class="fas fa-trash"></i> Delete
															</a> &nbsp;
															<a v-show="k === types.length - 1" href="javascript:void(0)" class="btn btn-sm btn-create"
																@click="addLc">
																<i class="fas fa-plus"></i> New Type
															</a>
														</div>
													</div>
												</div>
												<div class="card-body">
													<div v-for="(isiDetail, d) in item.item" :key="d" class="row">
														<!-- <div class="row"> -->

														<div class="col-md-3">
															<div class="form-group">
																<label for="">Item</label>
																<!-- <select v-model="isiDetail.item_id" class="form-control form-control-sm"
																	data-live-search="true" placeholder="Choose an Item" required>
																	<option v-for="(isi, key) in listItemBank" :key="key" :value="isi.id">
																		{{ isi.item_name }} - {{ isi.item_number }}
																	</option>
																</select> -->
																<v-select v-model="isiDetail.item_id" :options="listItemBank" :reduce="listItemBank => listItemBank.id" label="item_name">
																	
																</v-select>
															</div>
														</div>
														<div class="col-md-2">
															<label for="">Currency</label>
															<select v-model="isiDetail.currency_id" class="form-control form-control-sm"
																data-live-search="true" placeholder="Choose an Currency" required>
																<option v-for="(isi, key) in listCurrency" :key="key" :value="isi.id">
																	{{ isi.code }}
																</option>
															</select>
															<!-- <v-select v-model="isiDetail.curr" :options="listCurrency" label="code" @input="changeCurrCharge">
															</v-select> -->
														</div>
														<div class="col-md-2">
															<div class="form-group">
																<label for="">Price</label>
																<input v-model="isiDetail.price" type="text" class="form-control form-control-sm"
																	placeholder="Price" required />
															</div>
														</div>
														<!-- <div class="col-md-2">
															<label for="">Qty</label>
															<input v-model="isiDetail.qty" type="number" class="form-control form-control-sm" placeholder="Qty" required />
														</div> -->
														<div class="col-md-3">
															<div class="form-group">
																<label for="">Remark</label>
																<input v-model="isiDetail.remarks" type="text" class="form-control form-control-sm"
																	placeholder="Remarks" required />
															</div>
														</div>
														<div class="col-md-2 pt-1">
															<br>
															<a v-show="d || (!d && types[k].item.length > 1)" href="javascript:void(0)"
																class="btn btn-danger btn-sm" @click="removeDetail(d, k)"> <i class="fas fa-trash"></i>
															</a> &nbsp;
															<a v-show="d === types[k].item.length - 1" href="javascript:void(0)"
																class="btn btn-create btn-sm" @click="addDetail(k)"><i class="fas fa-plus"></i></a>
															&nbsp;
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<ul class="nav nav-tabs " style="border: 0;">
						<li class="active"><a href="#" class="btn btn-sm btn-head text-white">Remarks</a></li>
					</ul>
					<div class="card border">
						<div class="card-body">
							<div class="row mt-3">
								<div class="col-12">
									<div class="form-row">
										<!-- multiple remarks -->
										<div class="col-12" style="display: flex; align-items: center;">
											<label class="switch">
												<input type="checkbox" @click="toggleCheckbox">
												<div class="slider round"></div>
											</label>
											<span style="margin-left: 10px; font-size: 16px;">Activate Editor</span>
										</div>
										<div :class="'mt-3 col-md-' + (usingEditor ? '12' : '6')">
											<label for="">Remarks</label>
											<div v-for="(item, k) in remarks" :key="k" class="row mb-2">
												<div  v-show="usingEditor" class="col-md-12">
													<quill-editor v-model="item.valueEditor" style="width: 100%;" />
												</div>
												<div v-show="!usingEditor" class="col-md-9" >

													<input v-model="item.value" type="text" class="form-control form-control-sm"
														placeholder="enter remarks">
												</div>
												<div v-show="!usingEditor" class="col-md-3" >
													<a v-show="k || (!k && remarks.length > 1)" href="javascript:void(0)"
														class="btn btn-danger btn-sm" @click="removeRemarks(k)"> <i class="fas fa-trash"></i>
													</a> &nbsp;
													<a v-show="k === remarks.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm"
														@click="addRemarks"><i class="fas fa-plus"></i></a>
													&nbsp;
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row">

						<div class="col-md-12 mb-3">
							<div class="float-right">
								<button type="button" class="btn btn-cancel btn-sm mr-1" @click="back">Cancel</button>
								<input type="submit" class="btn btn-create btn-sm" value="Save" />
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</Layout>
</template>

<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
// import PageHeader from '@components/page-header'
// import Multiselect from 'vue-multiselect'
// import Vuetable from 'vuetable-2/src/components/Vuetable'
import DatePicker from 'vue2-datepicker';
import moment from 'moment'
// import axios from 'axios'
import {
	mapState,
	mapActions
} from 'vuex'
import Swal from 'sweetalert2'
import { quillEditor } from "vue-quill-editor";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// console.log(dataCurrency, 'hello motherfucker')

export default {
	name: 'Formreate',
	page: {
		title: 'Quotation',
		meta: [{
			name: 'description',
			content: appConfig.description,
		},],
	},
	components: {
		// appConfig,
		Layout,
		// PageHeader,
		DatePicker,
		quillEditor
		// Multiselect,
		// Vuetable
	},
	data() {
		return {
			title: 'Create Quotation',
			momentFormat: {
				// this.dataPayload.year
				stringify: (date) => {
					return date ? moment(this.dataQuotation.date_input).format('YYYY-MM-DD') : ''
				},
			},
			momentFormat2: {
				// this.dataPayload.year
				stringify: (date) => {
					return date ? moment(this.dataQuotation.validity_date).format('YYYY-MM-DD') : ''
				},
			},
			dataQuotation: {
				// curr:JSON.parse(localStorage.getItem('default_currency')),
				charges: [],
			},
			trackId: 1,
			remarks: [{
				value: '',
				valueEditor: ''
			}],
			includeTax: 0,
			isManualNumber: false,
			textarea: '',
			value: null,
			value1: null,
			charges: [],
			fields: [{
				name: 'type',
				title: 'Type',
				sortField: 'type'
			}, {
				name: 'item.item_name',
				title: 'Item',
				sortField: 'item.item_name'
			}, {
				name: 'rate',
				title: 'Rate',
				sortField: 'rate'
			}, {
				name: 'curr.code',
				title: 'Currency',
				sortField: 'curr.code'
			}, {
				name: 'remarks',
				title: 'Remarks',
				sortField: 'remarks'
			}, {
				name: 'action',
				title: 'Action',
				titleClass: 'text-center',
				dataClass: 'text-center'
			}],
			sortOrder: [{
				field: 'type',
				direction: 'asc'
			}],
			// listItem: null,
			dataDetail: [],
			types: [{
				type: '',
				status_rename: '',
				item: [{
					item: '',
					price: '',
					// qty: '',
					remarks: '',
					item_id: '',
					curr: '',
					currency_id: ''
				}]
			}],
			detail: [{
				item: '',
				price: '',
				qty: '',
				remarks: '',
				item_id: '',
				curr: ''
			}],
			listPpn: [{
				id: '0',
				name: 'Non PPN'
			},
			{
				id: 1,
				name: '1%'
			},
			{
				id: 10,
				name: '10%'
			}
			],
			items: [{
				text: 'Master',
				href: '/',
			},
			{
				text: 'Quotation',
				href: '/quotation',
			},
			{
				text: 'Form Input',
				active: true,
			},
			],
			isLoading: false,
			isHidden: false,
			isManualPod:false,
			isManualPol:false,
			usingEditor: false
			// defaultCurrency:null
		}
	},
	computed: {
		...mapState('customer', ['listCustomerQuo']),
		...mapState('port', ['listPort']),
		...mapState('currency', ['listCurrency', 'dataCurrency']),
		...mapState('item', ['listItem', 'listItemBank']),
		...mapState('term', ['listTerm']),
		...mapState('autonumber', ['listAutonumber']),

	},
	mounted() {
		this.getListCustomerQuo()
		this.getListPort()
		this.getListCurrency()
		this.getListTerm()
		this.getListItem()
		this.getListAutonumber(11)
		this.getItemByCategoryBank({
			itemCategory: 1
		});

		// this.dataQuotation.curr = this.dataCurrency
		// this.getDefCur()

		// this.dataQuotation.curr = this.listCurrency.find(option => option.id === this.trackId);

	},

	methods: {
		...mapActions('customer', ['getListCustomerQuo']),
		...mapActions('port', ['getListPort']),
		...mapActions('currency', ['getListCurrency']),
		...mapActions('item', ['getListItem', 'getItemByCategoryBank']),
		...mapActions('term', ['getListTerm']),
		...mapActions('quotation', ['saveQuotation']),
		...mapActions('autonumber', ['getListAutonumber']),

		getTgl() {
			this.dataQuotation.date_input = moment(this.dataQuotation.date_input).format('YYYY-MM-DD')
			console.log(this.dataQuotation.date_input)
		},


		getTgl2() {
			this.dataQuotation.validity_date = moment(this.dataQuotation.validity_date).format('YYYY-MM-DD')
			console.log(this.dataQuotation.validity_date)
		},

		// getDefCur(){
		// 	axios.get('/def/curr').then((response) => {
		// 		// console.log(response.data,'def cuurency')
		// 		this.types.item.curr = response.data
		// 		this.defaultCurrency = response.data.id
		// 		this.types.item.currency_id = response.data.id
		// 	}, () => {
		// 		console.log("gagal")
		// 	});
		// },
		triggerManualNumber(value) {
			this.isManualNumber = !this.isManualNumber
			if (value === true) {
				this.dataQuotation.numberText = ''
				
			}else{
				this.dataQuotation.autonumber_id = ''
				this.dataQuotation.is_manual_number = 1
			}
		},
		triggerManualPod(value) {
			this.isManualPod = !this.isManualPod
			if (value === true) {
				this.dataQuotation.podname = ''
				
			}else{
				this.dataQuotation.pod_id = ''
				this.dataQuotation.is_manual_pod = 1
			}
		},
		triggerManualPol(value) {
			this.isManualPol = !this.isManualPol
			if (value === true) {
				this.dataQuotation.polname = ''
				
			}else{
				this.dataQuotation.pol_id = ''
				this.dataQuotation.is_manual_pol = 1
			}
		},

		manualInput(value) {
			this.isHidden = !this.isHidden
			if (value === true) {
				this.dataQuotation.customer_manual = ''
			}
		},

		cekIncludeTax() {
			if (this.includeTax === 1) {
				// this.includeTax = 1
				// console.log(this.includeTax, 'satuuuu')
			} else if (this.includeTax === 0) {
				// console.log(this.includeTax, 'nolllllll')
			}
		},

		changeCustomer(value) {
			this.dataQuotation.customer_name = value.name
			this.dataQuotation.customer_id = value.id
			console.log('customer_id', this.dataQuotation.customer_name)
		},

		changePod(value) {
			this.dataQuotation.podname = value
			this.dataQuotation.pod = value.id
			console.log('pod id', this.dataQuotation.pod)
		},
		changePol(value) {
			this.dataQuotation.polname = value
			this.dataQuotation.pol = value.id
			console.log('pol id', this.dataQuotation.pol)
		},
		changeTerm(value) {
			this.dataQuotation.term = value
			this.dataQuotation.term_id = value.id
			console.log('term id', this.dataQuotation.term_id)
		},
		changeItem(value) {
			console.log(value)
			this.types.item.item = value
			this.types.item.item_id = value.id
			// console.log(' item', this.types.item.item_id)
		},
		// changePpn(value) {
		// 	this.dataQuotation.ppnShow = value
		// 	this.dataQuotation.ppn = value.id
		// 	console.log(this.dataQuotation.ppn, 'isi ppn')
		// 	let ppn = this.dataQuotation.ppn
		// 	console.log('ppn', ppn)

		// 	this.getListItem(pn).then((res) => {
		// 		this.listlistItem = res
		// 		console.log(res)
		// 		console.log(this.listlistItem, 'list item PPN')
		// 	}).catch((err) => {
		// 		console.log(err)
		// 	})
		// },

		changeCurrCharge(value) {
			// this.dataQuotation.curr = value
			// this.dataQuotation.currency_id = value.id
			this.types.item.curr = value
			this.types.item.currency_id = value.id
			console.log(' item', this.types.item.currency_id)
		},
		addRemarks() {
			if (!this.remarks) {
				return
			}
			this.remarks.push({
				value: '',
				valueEditor: ''
			});
		},
		removeRemarks(x) {
			console.log(x, 'index removeremarks')
			this.remarks.splice(x, 1)
			this.saveRemarks()
		},
		saveRemarks() {
			let parsed = JSON.stringify(this.remarks);
			const Toast = Swal.mixin({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 2000,
				timerProgressBar: true,
				onOpen: (toast) => {
					toast.addEventListener('mouseenter', Swal.stopTimer)
					toast.addEventListener('mouseleave', Swal.resumeTimer)
				}
			})

			Toast.fire({
				icon: 'success',
				title: 'successfully'
			})
			localStorage.setItem('Remarks', parsed)
		},

		remove(x) {
			this.types.splice(x, 1)
			this.saveLocalCharges()
		},
		addLc() {
			if (!this.types) {
				return
			}
			this.types.push({
				type: '',
				item: [{
					item: '',
					price: '',
					// qty: '',
					remarks: '',
					item_id: '',
					curr: '',
					currency_id: ''
				}]
			})
		},

		saveLocalCharges() {


			let parsed = JSON.stringify(this.types);
			const Toast = Swal.mixin({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 2000,
				timerProgressBar: true,
				onOpen: (toast) => {
					toast.addEventListener('mouseenter', Swal.stopTimer)
					toast.addEventListener('mouseleave', Swal.resumeTimer)
				}
			})

			Toast.fire({
				icon: 'success',
				title: 'successfully'
			})
			localStorage.setItem('Local Charges', parsed)
		},
		addDetail(k) {
			if (!this.types[k].item) {
				return
			}
			this.types[k].item.push({
				item: '',
				price: '',
				// qty: '',
				remarks: '',
				item_id: '',
				curr: '',
				currency_id: ''
			});
		},
		removeDetail(d, k) {
			this.types[k].item.splice(d, 1);
			this.saveDetail(k)
		},
		saveDetail(k) {
			const Toast = Swal.mixin({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 2000,
				timerProgressBar: true,
				onOpen: (toast) => {
					toast.addEventListener('mouseenter', Swal.stopTimer)
					toast.addEventListener('mouseleave', Swal.resumeTimer)
				}
			})

			Toast.fire({
				icon: 'success',
				title: 'successfully'
			})
		},
		submitData() {
			// this.dataQuotation.created_by = 2
			// this.dataQuotation.currency_id = this.dataQuotation.curr
			if (this.dataQuotation.customer_manual) {
				this.dataQuotation.customer_name = this.dataQuotation.customer_manual
				this.dataQuotation.customer_id = 0
			}
			this.dataQuotation.include_tax = this.includeTax
			this.dataQuotation.charges = this.types

			if(this.usingEditor) {
				this.remarks.forEach((dr, idx) => {
					this.remarks[idx]['value'] = dr.valueEditor
				});
			}

			this.dataQuotation.remarks = this.remarks
			this.dataQuotation.using_editor = this.usingEditor ? 1 : 0
			// console.log(this.dataQuotation.charges.item_id)
			// console.log('charge', this.dataQuotation.charges)


			console.log('data quotation', this.dataQuotation)
			let headers = {
				'Content-Type': 'application/json',

			}

			this.saveQuotation({
				payload: this.dataQuotation,
				headers: headers,

			})
				.then((res) => {
					console.log(res.data, 'datanya')
					if (res.data !== 0) {

						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})
						localStorage.removeItem('Local Charges')
						this.$router.push({
							name: 'Quotation List'
						})
					} else {
						Swal.fire({
							title: "Autonumber Can't be Null!",
							text: "Please check Master Autonumber Quotation!",
							icon: 'warning',
							// showCancelButton: true,
							confirmButtonColor: 'rgb(221, 107, 85)',
							// cancelButtonColor: '#C1C1C1',
							// reverseButtons: true,
							confirmButtonText: 'Ok'
						})
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},

		back() {
			this.$router.push({
				name: 'Quotation List'
			})
		},
		toggleCheckbox() {
			this.usingEditor = !this.usingEditor
			this.$emit('setCheckboxVal', this.usingEditor)

			this.remarks = [{
				value: '',
				valueEditor: ''
			}]
		}
	},
}

</script>
